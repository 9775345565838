import React, { FC, forwardRef } from 'react';

import { TypographyStyled } from './style';
import { TypographyProps } from './index';
import { motion } from 'framer-motion';

export const Typography: FC<TypographyProps> = ({
                                                  size = 'normal',
                                                  as = 'div',
                                                  color = 'primary',
                                                  transform = 'none',
                                                  weight = 'normal',
                                                  italic = false,
                                                  ellipsis = false,
                                                  align = 'left',
                                                  ref,
                                                  children
                                                }) => {
  return (
    <TypographyStyled
      as={as}
      ref={ref}
      size={size}
      color={color}
      transform={transform}
      weight={weight}
      italic={italic}
      ellipsis={ellipsis}
      align={align}
    >
      {children}
    </TypographyStyled>
  )
}

export const TypographyAnimated = motion(
  forwardRef<HTMLDivElement, TypographyProps>(
    (props, ref) =>
      Typography({
        ref,
        ...props
      })
  )
);
