import React from 'react';

import { Field } from '../../../interface/field';
import { Checkbox } from './Checkbox';

export interface CheckboxProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, keyof Field<boolean> | 'size'>,
  Partial<Field<boolean>> {
  label?: string;
  error?: string;
  helperText?: string;
  size?: 'small' | 'normal' | 'medium' | 'large';
  color?: string;
  hoverColor?: string;
  accentColor?: string;
  disabledColor?: string;
  radius?: string;
}

export default Checkbox;

