import { FlexProps } from './index';
import styled, { css } from 'styled-components';

export interface ContainerProps extends Omit<FlexProps, 'container' | 'column' | 'reverse' | 'children'> {
  direction: 'column' | 'row' | 'row-reverse' | 'column-reverse';
}

export type ItemProps = Pick<FlexProps, 'xs' | 's' | 'm' | 'l' | 'xl' | 'gap' | 'align'>;

export const ContainerStyled = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align ?? 'baseline'};
  gap: ${props => props.gap}px;
  ${props => props.flexWrap && css`
    flex-wrap: wrap;
  `}
`

export const ItemStyled = styled.div<ItemProps>`
  ${props => props.align && css`
    align-self: ${props.align};
  `}
  
  ${props => props.xs && css`
    @media ${props => props.theme.device.mobile} {
      flex: ${props.xs} ${props.xs} calc(${props.xs * 100}% - ${(props.gap ?? 0) * 2}px);
    }
  `}

  ${props => props.s && css`
    @media ${props => props.theme.device.mobileLarge} {
      flex: ${props.s} ${props.s} calc(${props.s * 100}% - ${(props.gap ?? 0) * 2}px);
    }
  `}

  ${props => props.m && css`
    @media ${props => props.theme.device.tablet} {
      flex: ${props.m} ${props.m} calc(${props.m * 100}% - ${(props.gap ?? 0) * 2}px);
    }
  `}

  ${props => props.l && css`
    @media ${props => props.theme.device.laptop} {
      flex: ${props.l} ${props.l} calc(${props.l * 100}% - ${(props.gap ?? 0) * 2}px);
    }
  `}

  ${props => props.xl && css`
    @media ${props => props.theme.device.laptopLarge} {
      flex: ${props.xl} ${props.xl} calc(${props.xl * 100}% - ${(props.gap ?? 0) * 2}px);
    }
  `}
`
