import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { routes } from '../constants';

import Home from './Home';

const Routing: FC = () => {
  return (
    <Routes>
      <Route path={routes.home} element={<Home/>}/>
    </Routes>
  )
}

export default Routing;
