import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${props => props.theme.fonts.families.normal};
    color: ${props => props.theme.colors.primary1};
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.neural.white};
  }
`;

