import styled, { css } from 'styled-components';
import { get } from 'lodash';

import { ButtonProps } from './index';

type ButtonStyledProps = Required<Pick<ButtonProps, 'variant' | 'size' | 'color' | 'radius' | 'hoverColor'>> & {
  iconOnly: boolean
}

export const ButtonStyled = styled.a<ButtonStyledProps>`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  text-align: center;
  font-size: ${props => props.theme.fonts.size.normal};
  line-height: ${props => props.theme.fonts.lineHeight.normal};
  border-radius: ${props => get(props.theme.radius, props.radius) ?? props.radius};
  color: ${props => props.theme.colors.neural.white};
  cursor: pointer;
  transition: all .2s ease-in-out;

  ${props => {
    switch (props.size) {
      case 'small': {
        return css`
          padding: 8px 40px;
          ${props.iconOnly && css`
            width: 32px;
            height: 32px;
          `}
        `
      }
      case 'medium': {
        return css`
          padding: 16px 40px;
          ${props.iconOnly && css`
            width: 48px;
            height: 48px;
          `}
        `
      }
      case 'large': {
        return css`
          padding: 20px 40px;
          ${props.iconOnly && css`
            width: 54px;
            height: 54px;
          `}
        `
      }
      default: {
        return css`
          padding: 13px 40px;
          ${props.iconOnly && css`
            width: 40px;
            height: 40px;
          `}
        `
      }
    }
  }}
  ${props => {
    switch (props.variant) {
      case 'link': {
        return css`
          border-bottom: 1px solid ${get(props.theme.colors, props.color) ?? props.color};
          padding: 0 0 3px 0;
          font-weight: 200;
          background: none;
          color: ${get(props.theme.colors, props.color) ?? props.color};

          ${props.iconOnly && css`
            border: 0;
            box-shadow: 1px 1px 10px 1px ${props.theme.colors.neural.lighter};
          `}
          
          &:hover {
            color: ${get(props.theme.colors, props.hoverColor) ?? props.hoverColor};
            border-bottom-color: ${get(props.theme.colors, props.hoverColor) ?? props.hoverColor};
          }

          &:disabled {
            pointer-events: none;
            color: ${props.theme.colors.primary1};
            border-bottom-color: ${props.theme.colors.primary1};
            opacity: 0.2;
          }
        `
      }
      case 'outlined': {
        return css`
          font-weight: 500;
          background: none;
          color: ${get(props.theme.colors, props.color) ?? props.color};
          border: 2px solid ${get(props.theme.colors, props.color) ?? props.color};

          &:hover {
            color: ${props.theme.colors.neural.white};
            background-color: ${get(props.theme.colors, props.color) ?? props.color};
          }

          &:disabled {
            pointer-events: none;
            color: ${props.theme.colors.primary1};
            border-color: ${props.theme.colors.primary1};
            opacity: 0.2;
          }
        `
      }
      default: {
        return css`
          font-weight: 500;
          background-color: ${get(props.theme.colors, props.color) ?? props.color};

          &:hover {
            background-color: ${get(props.theme.colors, props.hoverColor) ?? props.hoverColor};
          }

          &:disabled {
            pointer-events: none;
            background-color: ${props.theme.colors.primary1};
            opacity: 0.2;
          }
        `
      }
    }
  }}
  ${props => props.iconOnly && css`
    border-radius: 50%;
    padding: 0;
  `}
  & > span {
    margin-left: 10px;

    ${props => props.iconOnly && css`
      margin: 0;
    `}
  }
`;
