import styled from 'styled-components';

import theme from '../../../theme';
import { get } from 'lodash';
import { TypographyProps } from './index';

const themeAllowedSizes = Object.keys(theme.fonts.size).filter(str => !str.startsWith('h'));

type TypographyStyledProps = Required<Pick<TypographyProps, 'size' | 'as' | 'color' | 'transform' | 'weight' | 'italic' | 'ellipsis' | 'align'>>;

export const TypographyStyled = styled.div<TypographyStyledProps>`
  font-size: ${props => themeAllowedSizes.includes(props.size) ? props.theme.fonts.size[props.size as 'large' | 'medium' | 'normal' | 'small'] : props.size};
  color: ${props => get(props.theme.colors, props.color) ?? props.color};
  text-transform: ${props => props.transform};
  font-weight: ${props => props.weight};
  font-style: ${props => props.italic ? 'italic' : 'normal'};
  text-overflow: ${props => props.ellipsis ? 'ellipsis' : 'clip'};
  text-align: ${props => props.align};
`;

