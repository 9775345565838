export const size = {
  h1: '67px',
  h2: '50px',
  h3: '38px',
  h4: '28px',
  h5: '21px',
  h6: '16px',
  large: '20px',
  medium: '18px',
  normal: '16px',
  small: '14px',
}

export const lineHeight = {
  h1: '75px',
  h2: '57px',
  h3: '42px',
  h4: '32px',
  h5: '24px',
  h6: '18px',
  large: '28px',
  medium: '27px',
  normal: '22px',
  small: '19px',
}

export const families = {
  cursive: "'Libre Caslon Display', serif",
  normal: "'Outfit', sans-serif"
}
