import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { get } from 'lodash';

import { PlaceholderProps } from './index';

type PlaceholderStyledProps = Omit<Required<PlaceholderProps>, 'duration'>;


export const AnimatedLine = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
`;

export const PlaceholderStyled = styled.div<PlaceholderStyledProps>`
  position: relative;
  background-color: ${props => get(props.theme.colors, props.background) ?? props.background};
  opacity: 0.2;
  overflow: hidden;
  transform-origin: center;

  ${props => props.type === 'circle' ? css`
    width: ${props.radius};
    height: ${props.radius};
    border-radius: 50%;
  ` : css`
    width: ${props.width};
    height: ${get(props.theme.fonts.size, props.height) ?? props.height};
  `}
  & > ${AnimatedLine} {
    background: ${props => get(props.theme.gradients, props.gradient) ?? props.gradient};
  }
`;
