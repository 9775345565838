import React from 'react';

import { Flex } from './Flex'

export interface FlexProps extends Omit<React.HTMLProps<HTMLDivElement>, 'wrap'> {
  container?: boolean;
  column?: boolean;
  reverse?: boolean;
  justify?: 'start'
    | 'center'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'normal';
  align?: 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'normal';
  gap?: number;
  flexWrap?: boolean
  xs?: number;
  s?: number;
  m?: number;
  l?: number;
  xl?: number;
}

export default Flex;
