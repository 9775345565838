import React, { FC, useMemo } from 'react';

import { ContainerProps, ContainerStyled, ItemStyled } from './style';
import { FlexProps } from './index';


export const Flex: FC<FlexProps> = ({
                                      container = false,
                                      column = false,
                                      reverse = false,
                                      justify = 'center',
                                      align,
                                      gap = 0,
                                      flexWrap = true,
                                      children,
                                      xs,
                                      s,
                                      m,
                                      l,
                                      xl
                                    }) => {

  const direction = useMemo(() => {
    const baseDirection = column ? 'column' : 'row';
    return (reverse ? baseDirection + '-reverse' : baseDirection) as ContainerProps['direction'];
  }, [column, reverse])


  if (container) {
    const childrenWithGap = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {gap});
      }
      return child;
    });
    return (
      <ContainerStyled
        direction={direction}
        justify={justify}
        align={align}
        gap={gap}
        flexWrap={flexWrap}
      >
        {childrenWithGap}
      </ContainerStyled>
    )
  } else {
    return <ItemStyled
      xs={xs ? xs / 12 : undefined}
      s={s ? s / 12 : undefined}
      m={m ? m / 12 : undefined}
      l={l ? l / 12 : undefined}
      xl={xl ? xl / 12 : undefined}
      gap={gap}
      align={align}
    >
      {children}
    </ItemStyled>
  }
}


