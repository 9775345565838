import React from 'react';

import { TextField } from './TextField';
import { Field } from '../../../interface/field';

export interface TextFieldProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, keyof Field<string | number> | 'size'>,
  Omit<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, keyof Field<string | number> | keyof React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>,
  Partial<Field<string | number>> {
  textarea?: boolean
  label?: string;
  error?: string;
  helperText?: string;
  passwordVisibilityToggle?: boolean;
  variant?: 'normal' | 'outlined';
  size?: 'small' | 'normal' | 'medium' | 'large';
  color?: string;
  backgroundColor?: string;
  accentColor?: string;
  radius?: string;
}

export default TextField;
