import { colors } from './colors';
import { device } from './device';
import { radius } from './radius';
import { gradients } from './gradients';
import { size, lineHeight, families } from './fonts';

const theme = {
  colors,
  device,
  radius,
  gradients,
  fonts: {
    size,
    lineHeight,
    families
  }
}

export * from './globalStyle';

export default theme;
