import React, { FC, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FieldMetaProps, useFormikContext } from 'formik';

import { hash } from '../../../helpers/hash';
import { RadioStyled, ContainerStyled } from './style';
import { RadioProps } from './index';
import { TypographyAnimated } from '../../ui/Typography';
import { fadeIn } from '../_helpers/animations';

export const Radio: FC<RadioProps> = ({
                                              label,
                                              id,
                                              field,
                                              name,
                                              error,
                                              helperText,
                                              color = 'neural.light',
                                              hoverColor = 'accent',
                                              accentColor = 'primary1',
                                              radius = '50%',
                                              disabled,
                                              size = 'normal',
                                              ...props
                                            }) => {
  const primaryId = useMemo(() => id ? id : `Radio-${hash()}`, [id]);

  const formik = useFormikContext();

  const meta = useMemo<FieldMetaProps<string> | undefined>(() => {
    try {
      return formik.getFieldMeta(field?.name || name || '')
    } catch (err) {
      return undefined
    }
  }, [field, name, formik]);

  const errorShown = Boolean((meta?.touched && meta?.error && typeof meta?.error !== 'object') || error);

  return (
    <ContainerStyled>
      {/*@ts-ignore*/}
      <RadioStyled
        color={color}
        hoverColor={hoverColor}
        accentColor={accentColor}
        fieldSize={size}
        id={primaryId}
        error={errorShown}
        name={name}
        radius={radius}
        {...field}
        {...props}
      />
      <label htmlFor={primaryId}>
        <span/>
        {label}
      </label>
      <AnimatePresence exitBeforeEnter>
        {errorShown && (
          <TypographyAnimated
            key={primaryId + 'error'}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            variants={fadeIn}
            transition={{duration: 0.3}}
            color={'informational.danger'}
            size={'small'}
            weight={'300'}
          >
            {(typeof meta?.error === 'object' ? '' : meta?.error) || error}
          </TypographyAnimated>
        )}
        {!errorShown && helperText && (
          <TypographyAnimated
            key={primaryId + 'helper'}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            variants={fadeIn}
            transition={{duration: 0.3}}
            color={disabled ? 'neural.lighter' : color}
            size={'small'}
            weight={'300'}
          >
            {helperText}
          </TypographyAnimated>
        )}
      </AnimatePresence>
    </ContainerStyled>
  )
}
