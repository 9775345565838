import { Placeholder } from './Placeholder';

export interface PlaceholderProps {
  type?: 'circle' | 'box',
  radius?: string | number,
  height?: string | number,
  width?: string | number,
  background?: string,
  gradient?: string
  duration?: number
}

export default Placeholder
