import styled from 'styled-components';

import { HeaderProps } from './index';

export const HeaderStyled = styled.h1<Required<Pick<HeaderProps, 'variant' | 'align'>>>`
  font-family: ${props => props.theme.fonts.families.cursive};
  font-size: ${props => props.theme.fonts.size[props.variant]};
  color: ${props => props.theme.colors.neural.darker};
  text-align: ${props => props.align};
`;
