import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ButtonStyled } from './style';
import { ButtonProps } from './index';

export const Button: FC<ButtonProps> = ({
                                          icon,
                                          size = 'normal',
                                          variant = 'normal',
                                          color = 'primary1',
                                          radius = '0',
                                          as = 'button',
                                          hoverColor = 'primary2',
                                          children,
                                          ...props
                                        }) => {

  const getComponent = useCallback(() => {
    switch (as) {
      case 'link':
        return 'a';
      case 'button':
        return 'button';
      case 'nav_link':
        return Link;
      default:
        return 'a';
    }
  }, [as])

  const iconOnly = useMemo(() => {
    return !children && icon
  }, [icon, children])

  return (
    // @ts-ignore
    <ButtonStyled iconOnly={iconOnly} as={getComponent()} size={size} variant={variant} color={color}
                  radius={radius} hoverColor={hoverColor} {...props}>
      {children}
      {icon && (
        <span>
          {icon}
        </span>
      )}
    </ButtonStyled>
  )
}


