import React, { FC } from 'react';

import { PlaceholderStyled, AnimatedLine } from './style';
import { PlaceholderProps } from './index';

export const Placeholder: FC<PlaceholderProps> = ({
                                                    type = 'box',
                                                    radius = '40px',
                                                    width = '100%',
                                                    height = 'normal',
                                                    background = 'primary1',
                                                    gradient = 'shine',
                                                    duration = 2
                                                  }) => {
  return (
    <PlaceholderStyled
      type={type}
      radius={radius}
      width={width}
      height={height}
      background={background}
      gradient={gradient}
    >
      <AnimatedLine
        animate={{
          left: '100%',
        }}
        initial={{
          left: '-100%'
        }}
        transition={{
          duration,
          repeat: Infinity,
        }}
      />
    </PlaceholderStyled>
  )
}
