export const colors = {
  primary1: '#040202',
  primary2: '#A54201',
  secondary: '#BA9681',
  accent: '#D0BEB6',
  neural: {
    darker: '#53290D',
    dark: '#422020',
    neural: '#693333',
    light: '#363636',
    lighter: '#757575',
    white: '#ffffff'
  },
  informational: {
    danger: '#c13333',
    warning: '#d3b03c',
    success: '#67a234',
    info: '#2d89a2'
  }
}
