import React, { FC } from 'react'
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import Header from 'components/ui/Header';
import Typography  from 'components/ui/Typography';
import Placeholder from 'components/layout/Placeholder';
import Button from '../../components/form/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '../../components/form/TextField';
import Flex from '../../components/layout/Flex';
import Checkbox from '../../components/form/Checkbox';
import Radio from '../../components/form/Radio';

export const Home: FC = () => {
  return (
    <div>
      <Flex container column>
        <Formik validationSchema={yup.object({
          email: yup.string().email().required()
        })} initialValues={{email: ''}} onSubmit={console.log} validateOnChange={true}>
          {({handleSubmit}) => (
            <>
              <Flex container column gap={20}>
                <Header variant={'h3'}>Text Fields</Header>

                <Header variant={'h4'}>Variant: Normal</Header>
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'small'}
                  name={'email'}
                  component={TextField}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  name={'email'}
                  component={TextField}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'medium'}
                  name={'email'}
                  component={TextField}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'large'}
                  name={'email'}
                  component={TextField}
                />

                <Header variant={'h4'}>Variant: Outlined</Header>
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'small'}
                  name={'email'}
                  variant={'outlined'}
                  component={TextField}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  variant={'outlined'}
                  name={'email'}
                  component={TextField}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'medium'}
                  variant={'outlined'}
                  name={'email'}
                  component={TextField}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'large'}
                  variant={'outlined'}
                  name={'email'}
                  component={TextField}
                />

                <Header variant={'h4'}>Text Area</Header>
                <Field
                  textarea
                  rows={10}
                  cols={50}
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'large'}
                  name={'email'}
                  component={TextField}
                />
              </Flex>
              <Flex container column gap={20}>
                <Header variant={'h3'}>Buttons</Header>

                <Header variant={'h4'}>Variant: Normal</Header>
                <Button size={'small'}>Hello world</Button>
                <Button>Hello world</Button>
                <Button size={'medium'}>Hello world</Button>
                <Button size={'large'}>Hello world</Button>

                <Header variant={'h4'}>Variant: Link</Header>
                <Button variant={'link'}>Hello world</Button>

                <Header variant={'h4'}>Variant: Outlined</Header>
                <Button variant={'outlined'} size={'small'}>Hello world</Button>
                <Button variant={'outlined'}>Hello world</Button>
                <Button variant={'outlined'} size={'medium'}>Hello world</Button>
                <Button variant={'outlined'} size={'large'}>Hello world</Button>

                <Header variant={'h4'}>Variant: Icon</Header>
                <Button size={'small'} icon={<FontAwesomeIcon icon={'eye'}/>}>Hello world</Button>
                <Button icon={<FontAwesomeIcon icon={'eye'}/>}>Hello world</Button>
                <Button icon={<FontAwesomeIcon icon={'eye'}/>} size={'medium'}>Hello world</Button>
                <Button icon={<FontAwesomeIcon icon={'eye'}/>} size={'large'}>Hello world</Button>
                <Button size={'small'} icon={<FontAwesomeIcon icon={'eye'}/>}/>
                <Button icon={<FontAwesomeIcon icon={'eye'}/>}/>
                <Button icon={<FontAwesomeIcon icon={'eye'}/>} size={'medium'}/>
                <Button icon={<FontAwesomeIcon icon={'eye'}/>} size={'large'}/>
              </Flex>
              <Flex container column gap={20}>
                <Header variant={'h3'}>Checkboxes</Header>
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'small'}
                  component={Checkbox}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  component={Checkbox}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'medium'}
                  component={Checkbox}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'large'}
                  component={Checkbox}
                />
              </Flex>
              <Flex container column gap={20}>
                <Header variant={'h3'}>Radio</Header>
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'small'}
                  name={'radio'}
                  component={Radio}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  name={'radio'}
                  component={Radio}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'medium'}
                  name={'radio'}
                  component={Radio}
                />
                <Field
                  helperText={'Helper text'}
                  label={'Hello world'}
                  size={'large'}
                  name={'radio'}
                  component={Radio}
                />
              </Flex>
              <Flex container column gap={20}>
                <Header variant={'h3'}>Placeholders</Header>
                <Placeholder type={'circle'}/>
                <Placeholder width={'200px'} height={'50px'}/>
                <Placeholder width={'200px'} height={'10px'}/>
                <Placeholder width={'200px'} height={'10px'}/>
                <Placeholder width={'200px'} height={'10px'}/>
                <Placeholder width={'200px'} height={'10px'}/>
              </Flex>
              <Flex container column>
                <Header variant={'h1'}>Header</Header>
                <Header variant={'h2'}>Header</Header>
                <Header variant={'h3'}>Header</Header>
                <Header variant={'h4'}>Header</Header>
                <Header variant={'h5'}>Header</Header>
                <Header variant={'h6'}>Header</Header>
              </Flex>
              <Flex container column gap={20}>
                <Header variant={'h3'}>Typography</Header>
                <Typography size="small">Hello world</Typography>
                <Typography>Hello world</Typography>
                <Typography size="medium">Hello world</Typography>
                <Typography size="large">Hello world</Typography>
                <Typography italic size="small">Hello world</Typography>
                <Typography italic>Hello world</Typography>
                <Typography italic size="medium">Hello world</Typography>
                <Typography italic size="large">Hello world</Typography>
                <Typography weight={'bold'} size="small">Hello world</Typography>
                <Typography weight={'bold'}>Hello world</Typography>
                <Typography weight={'bold'} size="medium">Hello world</Typography>
                <Typography weight={'bold'} size="large">Hello world</Typography>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </div>
  )
}
