import React, { ForwardedRef } from 'react';

import { Typography } from './Typography';

export interface TypographyProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'ref'> {
  size?: 'large' | 'medium' | 'normal' | 'small' | string,
  as?: 'div' | 'span' | 'p',
  color?: string,
  align?: 'left' | 'center' | 'right',
  transform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase',
  weight?: 'normal' | 'bold' | 'lighter' | 'bolder' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900',
  italic?: boolean,
  ellipsis?: boolean
  ref?: ForwardedRef<HTMLDivElement>
}

export { TypographyAnimated } from './Typography';

export default Typography;
