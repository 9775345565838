import React, { FC, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FieldMetaProps, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TypographyAnimated } from '../../ui/Typography';
import { hash } from '../../../helpers/hash';
import { IconContainerStyled, InputGroupStyled, LabelStyled, TextFieldStyled } from './style';
import { TextFieldProps } from './index';
import { fadeIn } from '../_helpers/animations';

export const TextField: FC<TextFieldProps> = ({
                                                label,
                                                passwordVisibilityToggle,
                                                textarea = false,
                                                field,
                                                error,
                                                helperText,
                                                form,
                                                id,
                                                type,
                                                name,
                                                size = 'normal',
                                                variant = 'normal',
                                                backgroundColor = 'neural.white',
                                                color = 'primary1',
                                                accentColor = 'primary2',
                                                radius = '0',
                                                disabled,
                                                ...props
                                              }) => {
  const formik = useFormikContext();

  const meta = useMemo<FieldMetaProps<string> | undefined>(() => {
    try {
      return formik.getFieldMeta(field?.name || name || '')
    } catch (err) {
      return undefined
    }
  }, [field, name, formik]);

  const primaryId = useMemo(() => id ? id : `TextField-${hash()}`, [id]);

  const shouldShowPasswordToggle = type === 'password' && !textarea && passwordVisibilityToggle;

  const [passwordShown, setPasswordShown] = useState(false);

  const typeMemo = useMemo(() => {
    if (shouldShowPasswordToggle && !textarea)
      return passwordShown ? 'text' : 'password';
    else if (textarea)
      return undefined
    else
      return type;
  }, [passwordShown, type, shouldShowPasswordToggle, textarea])

  const asProps = textarea ? {as: 'textarea'} : {};

  const errorShown = Boolean((meta?.touched && meta?.error && typeof meta?.error !== 'object') || error);

  return (
    <InputGroupStyled>
      {/*@ts-ignore*/}
      <TextFieldStyled
        id={primaryId}
        type={typeMemo}
        error={errorShown as any}
        color={color}
        backgroundColor={backgroundColor}
        accentColor={accentColor}
        radius={radius}
        variant={variant}
        fieldSize={size}
        placeholder={label}
        {...field}
        {...props}
        {...asProps}
      />
      <LabelStyled
        fieldSize={size}
        variant={variant}
        color={color}
        backgroundColor={backgroundColor}
        htmlFor={primaryId}
      >{label}</LabelStyled>
      {shouldShowPasswordToggle &&
        <IconContainerStyled fieldSize={size} onClick={() => setPasswordShown(val => !val)}>
          <FontAwesomeIcon icon={passwordShown ? 'eye' : 'eye-slash'}/>
        </IconContainerStyled>
      }
      <AnimatePresence exitBeforeEnter>
        {errorShown && (
          <TypographyAnimated
            key={primaryId + 'error'}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            variants={fadeIn}
            transition={{duration: 0.3}}
            color={'informational.danger'}
            size={'small'}
            weight={'300'}
          >
            {(typeof meta?.error === 'object' ? '' : meta?.error) || error}
          </TypographyAnimated>
        )}
        {!errorShown && helperText && (
          <TypographyAnimated
            key={primaryId + 'helper'}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            variants={fadeIn}
            transition={{duration: 0.3}}
            color={disabled ? 'neural.lighter' : color}
            size={'small'}
            weight={'300'}
          >
            {helperText}
          </TypographyAnimated>
        )}
      </AnimatePresence>
    </InputGroupStyled>
  )
}

