import styled, { css } from 'styled-components';
import { get } from 'lodash';

import { TextFieldProps } from './index';

type IconContainerStyledProps = {
  fieldSize: 'large' | 'medium' | 'normal' | 'small'
}
type LabelStyledProps = Required<Pick<TextFieldProps, 'color' | 'backgroundColor' | 'variant'>> & {
  fieldSize: 'large' | 'medium' | 'normal' | 'small'
}
type TextFieldStyledProps =
  Required<Pick<TextFieldProps, 'color' | 'backgroundColor' | 'accentColor' | 'radius' | 'error' | 'variant'>>
  & {
  fieldSize: 'large' | 'medium' | 'normal' | 'small'
}

export const InputGroupStyled = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 14px 0 0;
  margin: 2px 0;
`;

export const IconContainerStyled = styled.div<IconContainerStyledProps>`
  position: absolute;
  ${props => {
    switch (props.fieldSize) {
      case 'small':
        return css`
            top: 24px;
          `
      case 'medium':
        return css`
            top: 32px;
          `
      case 'large':
        return css`
            top: 36px;
          `
      default:
        return css`
            top: 29px;
          `
    }
  }}
  right: 10px;
  cursor: pointer;
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  background-color: ${props => get(props.theme.colors, props.backgroundColor) ?? props.backgroundColor};
  color: ${props => get(props.theme.colors, props.color) ?? props.color};
  position: absolute;
  display: block;
  top: 6px;
  left: 8px;
  padding: 0 8px;
  transition: 0.2s;
  font-size: ${props => props.theme.fonts.size.small};

  ${props => props.variant === 'outlined' && css`
    padding-left: 0;
    left: 0;
  `}
`;

export const TextFieldStyled = styled.input<TextFieldStyledProps>`
  outline: 0;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  ${props => {
    switch (props.variant) {
      case 'outlined':
        return css`
          border-bottom: 1px solid ${get(props.theme.colors, props.color) ?? props.color};
        `
      default:
        return css`
          border: 1px solid ${get(props.theme.colors, props.color) ?? props.color};
        `
    }
  }}
  font-size: ${props => props.theme.fonts.size.normal};
  color: ${props => get(props.theme.colors, props.color) ?? props.color};
  background-color: ${props => get(props.theme.colors, props.backgroundColor) ?? props.backgroundColor};
  border-radius: ${props => get(props.theme.radius, props.radius) ?? props.radius};
  ${props => {
    switch (props.fieldSize) {
      case 'small':
        return css`
          padding: 9px;
        `
      case 'medium':
        return css`
          padding: 17px;
        `
      case 'large':
        return css`
          padding: 21px;
        `
      default:
        return css`
          padding: 14px;
        `
    }
  }}
  ${props => props.variant === 'outlined' && css`
    padding-left: 0;
  `}
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ ${LabelStyled} {
    font-size: ${props => props.theme.fonts.size.normal};
    cursor: text;
    ${props => {
      switch (props.fieldSize) {
        case 'small':
          return css`
            top: 24px;
          `
        case 'medium':
          return css`
            top: 32px;
          `
        case 'large':
          return css`
            top: 36px;
          `
        default:
          return css`
            top: 29px;
          `
      }
    }}
  }

  &:focus ~ ${LabelStyled} {
    position: absolute;
    top: 6px;
    display: block;
    transition: 0.2s;
    font-size: ${props => props.theme.fonts.size.small};
    color: ${props => get(props.theme.colors, props.accentColor) ?? props.accentColor};
  }

  &:focus {
    border-color: ${props => get(props.theme.colors, props.accentColor) ?? props.accentColor};
  }

  &:disabled {
    border-color: ${props => props.theme.colors.neural.lighter};
    color: ${props => props.theme.colors.neural.lighter};
  }

  &:disabled ~ ${LabelStyled} {
    color: ${props => props.theme.colors.neural.lighter};
  }

  ${props => Boolean(props.error) && css`
    border-color: ${props => props.theme.colors.informational.danger};

    ~ ${LabelStyled} {
      color: ${props => props.theme.colors.informational.danger};
    }
  `}

`;
