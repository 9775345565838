export const fadeIn = {
  initial: {
    opacity: 0,
    top: -100
  },
  animate: {
    opacity: 1,
    top: 0
  },
  exit: {
    opacity: 0,
    top: 100
  }
}
