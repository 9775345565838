import styled, { css } from 'styled-components';
import { get } from 'lodash';

import { RadioProps } from './index';

type RadioStyledProps =
  Required<Pick<RadioProps, 'color' | 'hoverColor' | 'radius' | 'accentColor' | 'disabledColor'>>
  & {
  fieldSize: RadioProps['size'];
  error: boolean;
}

export const ContainerStyled = styled.div`
  position: relative;
`;

export const RadioStyled = styled.input.attrs({type: 'radio'})<RadioStyledProps>`
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;

  & + label {
    position: relative;
    display: inline-flex;
    margin: 0;
    align-items: center;
    color: ${props => get(props.theme.colors, props.color) ?? props.color};
    transition: color 0.25s cubic-bezier(.4, .0, .23, 1);
    font-weight: 400;
    ${props => {
      switch (props.fieldSize) {
        case 'small': {
          return css`
            font-size: ${props => props.theme.fonts.size.small};
          `
        }
        case 'medium': {
          return css`
            font-size: ${props => props.theme.fonts.size.medium};
          `
        }
        case 'large': {
          return css`
            font-size: ${props => props.theme.fonts.size.large};
          `
        }
        default:
          return css`
            font-size: ${props => props.theme.fonts.size.normal};
          `
      }
    }}
  }

  & + label > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background: transparent;
    border: 1px solid ${props => get(props.theme.colors, props.color) ?? props.color};
    border-radius: ${props => get(props.theme.radius, props.radius) ?? props.radius};
    cursor: pointer;
    transition: all 0.25s cubic-bezier(.4, .0, .23, 1);

    ${props => {
      switch (props.fieldSize) {
        case 'small': {
          return css`
            width: 22px;
            height: 22px;
          `
        }
        case 'medium': {
          return css`
            width: 38px;
            height: 38px;
          `
        }
        case 'large': {
          return css`
            width: 44px;
            height: 44px;
          `
        }
        default:
          return css`
            width: 30px;
            height: 30px;
          `
      }
    }}
  }

  & + label:hover,
  &:focus + label {
    color: ${props => get(props.theme.colors, props.color) ?? props.color};
  }

  & + label:hover > span,
  &:focus + label > span {
    background: ${props => get(props.theme.colors, props.hoverColor) ?? props.hoverColor};
  }

  &:checked + label {
    color: ${props => get(props.theme.colors, props.accentColor) ?? props.accentColor}
  }

  &:checked + label > span {
    border: 1px solid ${props => get(props.theme.colors, props.accentColor) ?? props.accentColor};
    animation: shrink-bounce .2s cubic-bezier(.4, .0, .23, 1);
  }

  &:checked + label > span:before {
    content: "";
    position: absolute;
    background-color: ${props => get(props.theme.colors, props.accentColor) ?? props.accentColor};
    animation: shrink-bounce-2 .125s cubic-bezier(.4, .0, .23, 1) forwards;
    border-radius: ${props => get(props.theme.radius, props.radius) ?? props.radius};
    ${props => {
      switch (props.fieldSize) {
        case 'small': {
          return css`
            width: 16px;
            height: 16px;
          `
        }
        case 'medium': {
          return css`
            width: 32px;
            height: 32px;
          `
        }
        case 'large': {
          return css`
            width: 38px;
            height: 38px;
          `
        }
        default:
          return css`
            width: 24px;
            height: 24px;
          `
      }
    }}
  }

  &:disabled + label {
    color: ${props => get(props.theme.colors, props.disabledColor) ?? props.disabledColor};
  }

  &:disabled + label > span {
    border-color: ${props => get(props.theme.colors, props.disabledColor) ?? props.disabledColor};
    background-color: transparent;
  }

  ${props => props.error && css`
    & + label {
      color: ${props => props.theme.colors.informational.danger};

      & > span {
        border-color: ${props => props.theme.colors.informational.danger};
      }
    }
  `
  };

  @keyframes shrink-bounce {
    0% {
      transform: scale(1);
    }

    33% {
      transform: scale(.85);
    }

    100% {
      transform: scale(1);
    }
  };

  @keyframes shrink-bounce-2 {
    0% {
      transform: scale(1);
    }
    66% {
      transform: scale(.65);
    }
    100% {
      transform: scale(1);
    }
  };
`;
