import React from 'react';

import { Header } from './Header'

export interface HeaderProps extends React.HTMLProps<HTMLHeadingElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  align?: 'left' | 'center' | 'right'
}

export default Header;
