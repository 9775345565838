import React, { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

import { Button } from './Button';

export interface ButtonProps extends Partial<Omit<React.HTMLProps<HTMLAnchorElement & HTMLButtonElement>, keyof LinkProps | 'size'>>, Partial<LinkProps> {
  variant?: 'normal' | 'link' | 'outlined',
  size?: 'small' | 'normal' | 'medium' | 'large',
  color?: string,
  hoverColor?: string,
  icon?: ReactNode,
  as?: 'nav_link' | 'button' | 'link',
  radius?: string,
}

export default Button;
