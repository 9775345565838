import React, { FC } from 'react';

import { HeaderProps } from './index';
import { HeaderStyled } from './style';

export const Header: FC<HeaderProps> = ({
                                          variant = 'h1',
                                          align = 'left',
                                          children,
                                        }) => {
  return (
    <HeaderStyled align={align} variant={variant} as={variant}>
      {children}
    </HeaderStyled>
  );
}
